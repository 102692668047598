<template>
  <div>
    <souscription-sante-senior v-if="this.risque.label=='Santé Séniors'"></souscription-sante-senior>
    <souscription-sante-actif v-if="this.risque.label=='Santé Actifs'"></souscription-sante-actif>
    <souscription-sante-tns v-if="this.risque.label=='Santé TNS'"></souscription-sante-tns>
    <souscription-deux-roues v-if="this.risque.label=='2-Roues'"></souscription-deux-roues>
    <souscription-emprunteur v-if="this.risque.label=='Emprunteur'"></souscription-emprunteur>
  </div>
</template>

<script>
import SouscriptionSanteTns from "./Souscription/SanteTns/SouscriptionProjet.vue";
import SouscriptionSanteSenior from "./Souscription/SanteSenior/SouscriptionProjet.vue";
import SouscriptionDeuxRoues from "./Souscription/DeuxRoues/SouscriptionProjet.vue";
import SouscriptionEmprunteur from "./Souscription/Emprunteur/SouscriptionProjet.vue";
import SouscriptionSanteActif from "./Souscription/SanteActif/SouscriptionProjet.vue";

export default {
  components: {
    SouscriptionSanteTns,
    SouscriptionSanteSenior,
    SouscriptionDeuxRoues,
    SouscriptionEmprunteur,
    SouscriptionSanteActif
  },
  data() {
    return {
      risque:null
    };
  },
  created(){
    this.$http
      .get(`devis/GetDevisByIDEtude/${this.$route.params.id}`)
      .then(r => {
        this.risque=r.data.risque
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {},
};
</script>
<template>
   <div>  
      <b-row>
        <b-col cols="12" class="mb-2">
          <h2 class="mb-0 text-uppercase font-weight-bolder">
              étape 3 : designation de l'entreprise
          </h2>
          <h6>
              Vérifiez et complétez les informations relatives à l'entreprise
          </h6>
        </b-col>
      </b-row>  
      <b-card-text v-if="renderComponent">
        <h4 class="title-custom-wizard"><u>ENTREPRISE</u></h4>
        <b-row class="mb-2">
          <div class="col">
              <h1 class="text-primary font-weight-bolder ml-1" ></h1>
          </div>
          <div class="col-auto">
            <b-button
              @click="addEntreprise()"
              variant="outline-primary"
              block
            >
            <feather-icon  
              icon="PlusIcon"
              class="mr-50"
            />
              AJOUTER UNE ENTREPRISE
            </b-button>
          </div>
        </b-row>
        <!-- <b-row v-if="EntrepriseGlobalLocal.length>0">
          <div class="col-12">
              <div class="table-responsive">
                <table role="table" aria-busy="false" aria-colcount="3" class="table b-table table-bordered" id="__BVID__2166">

                    <thead role="rowgroup" class="">
 
                      <tr role="row" class="">
                          <th role="columnheader" scope="col" aria-colindex="1" class="text-center">
                            <div>Dénomination commerciale</div>
                          </th>
                          <th role="columnheader" scope="col" aria-colindex="2" class="text-center">
                            <div>Siret</div>
                          </th>
                          <th role="columnheader" scope="col" aria-colindex="3" class="text-center">
                            <div>Raison sociale</div>
                          </th>
                          <th role="columnheader" scope="col" aria-colindex="3" class="text-center">
                            <div>Actions</div>
                          </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr  v-for="(ep,index) in EntrepriseGlobalLocal" :key="index" >
                          <td aria-colindex="1" role="cell" class="text-center">{{ep.denomination_commercial}}</td>
                          <td aria-colindex="1" role="cell" class="text-center">{{ep.siret}}</td>
                          <td aria-colindex="3" role="cell" class="text-center">{{ep.raison_sociale}}</td>
                          <td aria-colindex="3" role="cell" class="text-center">
                            <b-form-radio
                              v-model="choosedEntreprise"
                              :value="ep.id"
                              name="entreprise_state"
                              style="margin:0 50%"
                            >
                            </b-form-radio>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </div>
        </b-row> -->

        <div class="row">
          <div class="col-md-12 col-lg-12" v-for="(entreprise,val) in EntrepriseArray" :key="val" >
            <div class="card"><!---->
              <div class="card-body">
                <div class="row">
                  <div class="col-md-1 text-center my-auto">
                    <div class="custom-control custom-checkbox cursor-pointer" style="margin:0 50%"  @click="changeChoosedEntreprise(val)"> 
                      <input type="checkbox" :name="'some-radios'+val" class="custom-control-input" value="A" id="__BVID__616"
                            :checked="EntrepriseArray[val].choosedEntreprise">
                      <label class="custom-control-label" for="__BVID__616">  </label>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <b-row>
                      <b-col md="4">
                        <b-form-group
                          label="Siret"
                          label-for="siret"
                        >
                        <cleave
                            id="siret"
                            v-model="entreprise.siret"
                            autocomplete="nope"
                            placeholder="Siret"
                            class="form-control"
                            type="number"
                            :raw="false"
                            :options="options.blockSiret"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.siret')">
                            <span v-if="hasErrors('entreprise.'+val+'.siret')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">{{ hasErrors('entreprise.'+val+'.siret') }}</span>
                            <span v-else>Le numéro de SIRET est erroné.</span>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col md="8" class="text-left mt-2">
                        <b-button variant="primary" :disabled="disabled_siret || !entreprise.siret || entreprise.siret.length != 14" @click="GetBySiret(entreprise.siret, val)">
                          Vérifier mon siret
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group
                          label="Enseigne"
                          label-for="enseigne"
                        >
                            <b-form-input
                              id="enseigne"
                              v-model="entreprise.denomination_commercial"
                              autocomplete="nope"
                              trim
                              placeholder="Enseigne"
                            />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.denomination_commercial')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Raison sociale"
                          label-for="raison_sociale"
                        >
                          <b-form-input
                            id="raison_sociale"
                            v-model="entreprise.raison_sociale"
                            autocomplete="nope"
                            trim
                            placeholder="Raison sociale"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.raison_sociale')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- <b-form-invalid-feedback :state="!hasErrors('entreprise.raison_sociale')">
                          Veuillez renseigner le(s) champ(s) obligatoire(s).
                        </b-form-invalid-feedback> -->
                      </b-col>
                      <b-col md="4" v-if="false">
                        <b-form-group
                          label="Siret"
                          label-for="siret"
                        >
                          <!-- <b-form-input
                            id="siret"
                            v-model="entreprise.siret"
                            type="number"
                            autocomplete="nope"
                            trim
                            placeholder="Siret"
                          /> -->
                          <cleave
                            id="siret"
                            v-model="entreprise.siret"
                            autocomplete="nope"
                            placeholder="Siret"
                            class="form-control"
                            type="number"
                            :raw="false"
                            :options="options.blockSiret"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.siret')">
                            <span v-if="hasErrors('entreprise.'+val+'.siret')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">{{ hasErrors('entreprise.'+val+'.siret') }}</span>
                            <span v-else>Le numéro de SIRET est erroné.</span>
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- <b-form-invalid-feedback :state="!$v.EntrepriseLocal.siret.$error">
                          Veuillez renseigner le(s) champ(s) obligatoire(s).
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="!hasErrors('entreprise.siret')">
                          Le numéro de SIRET est erroné.
                        </b-form-invalid-feedback> -->
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Code Naf"
                          label-for="code_naf_id"
                        >
                        <v-select
                          v-model="entreprise.code_naf_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="codeNafData"
                          input-id="id"
                        >
                          <template #option="{code, libelle }">
                            <span class="ml-50">  {{ code }} {{ libelle }}</span>
                          </template>

                          <template #selected-option="{ code }">
                            <span class="ml-50"> {{ code }} </span>
                          </template>
                          <div slot="no-options">Aucune correspondance.</div>
                        </v-select>
                          <!-- <b-form-select
                            v-model="entreprise.code_naf_id"
                          >
                            <b-form-select-option
                              v-for="(cn,index) in codeNafData"
                              :key="index"
                              :value="cn.id"
                            >
                              {{ cn.code }} {{ cn.libelle }}
                            </b-form-select-option>
                          </b-form-select> -->
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.code_naf_id')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- <b-form-invalid-feedback :state="!hasErrors('entreprise.code_naf_id')">
                          Veuillez renseigner le(s) champ(s) obligatoire(s).
                        </b-form-invalid-feedback> -->
                      </b-col>
                      <b-col md="8">
                        <b-form-group
                          label="Forme juridique"
                          label-for="forme_juridique"
                        >
                          <b-form-select
                            v-model="entreprise.forme_juridique"
                          >
                            <b-form-select-option
                              v-for="(fg,index) in FormJuridique"
                              :key="index"
                              :value="fg.value"
                            >
                              {{ fg.text }}
                            </b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.forme_juridique')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- <b-form-invalid-feedback :state="!hasErrors('entreprise.forme_juridique')">
                          Veuillez renseigner le(s) champ(s) obligatoire(s).
                        </b-form-invalid-feedback> -->
                        <!-- <b-form-invalid-feedback :state="!$v.entreprise.siret.$error">
                          Veuillez renseigner le(s) champ(s) obligatoire(s
                        </b-form-invalid-feedback> -->
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- <b-col md="12"><h4 class="title-custom-wizard mb-2"><u>Adresse</u></h4></b-col> -->
                      <b-col md="4">
                        <b-form-group
                          label="N° et libellé de la voie"
                          label-for="libelle"
                        >
                          <b-form-input
                            id="libelle"
                            v-model="entreprise.moyen_contact.libelle"
                            autocomplete="nope"
                            trim
                            placeholder="N° et libellé de la voie"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.libelle')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Complément d'adresse"
                          label-for="complement_adresse"
                        >
                          <b-form-input
                            id="complement_adresse"
                            v-model="entreprise.moyen_contact.complement_adresse"
                            autocomplete="nope"
                            trim
                            placeholder="Complément d'adresse"
                            
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Lieu-dit"
                          label-for="lieu_dit"
                        >
                          <b-form-input
                            id="lieu_dit"
                            v-model="entreprise.moyen_contact.lieu_dit_ou_bp"
                            autocomplete="nope"
                            trim
                            placeholder="Lieu-dit"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Code Postal"
                          label-for="code_postale_entreprise"
                        >
                          <cleave
                            id="code_postale_entreprise"
                            v-model="EntrepriseArray[val].moyen_contact.code_postal"
                            autocomplete="nope"
                            type="number"
                            class="form-control"
                            :raw="false"
                            :options="options.cp"
                            placeholder="Code Postal"
                            @keyup.native="cpKeyupEntreprise(val,EntrepriseArray[val].moyen_contact.code_postal)"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.code_postal')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="Ville"
                          :label-for="'ville'+val"
                        >
                          <b-form-select
                          v-model="entreprise.moyen_contact.ville_id"
                          :id="'ville'+val"
                          >
                            <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                            <b-form-select-option v-for="(val, value) in entreprise.moyen_contact.villesLocal" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.ville_id')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                   <!--Période de clôture d’exercice -->
                      <b-col md="4">
                         <b-form-group
                          label="Période de clôture d’exercice"
                          :label-for="'date_cloture_exercice'+val"
                        >
                          <cleave
                            :id="'date_cloture_exercice'+val"
                            v-model="entreprise.date_cloture_exercice"   
                            class="form-control"
                            :raw="false"
                            :options="options.date_cloture"
                            placeholder="jour/mois"
                          />
                          <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.date_cloture_exercice')">
                            Veuillez renseigner le(s) champ(s) obligatoire(s).
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="col-md-2 text-center my-auto"  style="margin:50% 0" v-if="!entreprise.id">
                    <div class="d-inline" @click="removeEntreprise(val)">
                      <feather-icon   icon="XIcon" size="22" class="mr-50" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <b-row v-for="(entreprise,val) in EntrepriseArray" :key="val" class="mt-4">
          <b-col md="1" class="card">
            <div class="custom-control custom-checkbox cursor-pointer" style="margin:0% 50%;margin-top:30px" @click="changeChoosedEntreprise(val)"> 
                <input type="checkbox" :name="'some-radios'+val" class="custom-control-input" value="A" id="__BVID__616"
                       :checked="EntrepriseArray[val].choosedEntreprise">
                <label class="custom-control-label" for="__BVID__616">  </label>
            </div>
          </b-col>
          <b-col md="9">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Enseigne"
                  label-for="enseigne"
                >
                    <b-form-input
                      id="enseigne"
                      v-model="entreprise.denomination_commercial"
                      autocomplete="nope"
                      trim
                      placeholder="Enseigne"
                    />
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.denomination_commercial')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Raison sociale"
                  label-for="raison_sociale"
                >
                  <b-form-input
                    id="raison_sociale"
                    v-model="entreprise.raison_sociale"
                    autocomplete="nope"
                    trim
                    placeholder="Raison sociale"
                  />
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.raison_sociale')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Siret"
                  label-for="siret"
                >

                  <cleave
                    id="siret"
                    v-model="entreprise.siret"
                    autocomplete="nope"
                    placeholder="Siret"
                    class="form-control"
                    type="number"
                    :raw="false"
                    :options="options.blockSiret"
                  />
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.siret')">
                    <span v-if="hasErrors('entreprise.'+val+'.siret')=='Veuillez renseigner le(s) champ(s) obligatoire(s)'">{{ hasErrors('entreprise.'+val+'.siret') }}</span>
                    <span v-else>Le numéro de SIRET est erroné.</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Code Naf"
                  label-for="code_naf_id"
                >
                  <b-form-select
                    v-model="entreprise.code_naf_id"
                  >
                    <b-form-select-option
                      v-for="(cn,index) in codeNafData"
                      :key="index"
                      :value="cn.id"
                    >
                      {{ cn.code }} {{ cn.libelle }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.code_naf_id')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Forme juridique"
                  label-for="forme_juridique"
                >
                  <b-form-select
                    v-model="entreprise.forme_juridique"
                  >
                    <b-form-select-option
                      v-for="(fg,index) in FormJuridique"
                      :key="index"
                      :value="fg.value"
                    >
                      {{ fg.text }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.forme_juridique')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <b-form-group
                  label="N° et libellé de la voie*"
                  label-for="libelle"
                >
                  <b-form-input
                    id="libelle"
                    v-model="entreprise.moyen_contact.libelle"
                    autocomplete="nope"
                    trim
                    placeholder="N° et libellé de la voie"
                  />
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.libelle')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Lieu-dit"
                  label-for="lieu_dit"
                >
                  <b-form-input
                    id="lieu_dit"
                    v-model="entreprise.moyen_contact.lieu_dit_ou_bp"
                    autocomplete="nope"
                    trim
                    placeholder="Lieu-dit"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Complément d'adresse"
                  label-for="complement_adresse"
                >
                  <b-form-input
                    id="complement_adresse"
                    v-model="entreprise.moyen_contact.complement_adresse"
                    autocomplete="nope"
                    trim
                    placeholder="Complément d'adresse"
                    
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Code Postal*"
                  label-for="code_postale_entreprise"
                >
                  <cleave
                    id="code_postale_entreprise"
                    v-model="EntrepriseArray[val].moyen_contact.code_postal"
                    autocomplete="nope"
                    type="number"
                    class="form-control"
                    :raw="false"
                    :options="options.cp"
                    placeholder="Code Postal"
                    @keyup.native="cpKeyupEntreprise(val,EntrepriseArray[val].moyen_contact.code_postal)"
                  />
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.code_postal')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Ville*"
                  :label-for="'ville'+val"
                >
                  <b-form-select
                  v-model="entreprise.moyen_contact.ville_id"
                  :id="'ville'+val"
                  >
                    <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                    <b-form-select-option v-for="(val, value) in entreprise.moyen_contact.villesLocal" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!hasErrors('entreprise.'+val+'.moyen_contact.ville_id')">
                    Veuillez renseigner le(s) champ(s) obligatoire(s).
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="2" class="text-center" style="margin-top:30px" v-if="!entreprise.id">
            <div class="d-inline" @click="removeEntreprise(val)">
              <feather-icon   icon="XIcon" size="22" class="mr-50" />
            </div>
          </b-col>
        </b-row> -->
        
      </b-card-text>

      <!-- <b-card-text v-if="objets.objet_sante_tns.conjoint">
        <h4 class="title-custom-wizard"><u>CONJOINT</u></h4>
        <b-row>
          <b-col md="6">
            
            <b-form-group
              label="Civilité"
              label-for="civilite"
            >
              <b-form-select
                v-model="ConjointLocal.civilite"
                id="civilite"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option value="MR">M.</b-form-select-option>
                <b-form-select-option value="MME">Mme</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('conjoint.civilite')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <b-form-input
                class="text-uppercase"
                id="nom"
                v-model="ConjointLocal.nom"
                trim
                placeholder="Nom"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('conjoint.nom')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prénom"
              label-for="prenom"
            >
              <b-form-input
                class="text-capitalize"
                id="prenom"
                v-model="ConjointLocal.prenom"
                trim
                placeholder="Prénom"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('conjoint.prenom')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date de naissance"
              label-for="date_naissance"
            >
              <flat-pickr
                class="form-control"
                v-model="ConjointLocal.date_naissance"
                id="date_naissance"
                :config="configDateNaissance"
                placeholder="Date naissance"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('conjoint.date_naissance')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Numéro securite sociale"
              label-for="num_secu"
            >
              <cleave
                id="num_secu"
                v-model="ConjointLocal.num_secu"
                class="form-control"
                :raw="false"
                :options="options.blockSecu"
                placeholder="Numéro securite sociale"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('conjoint.num_secu')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Code organisme"
              label-for="numeroOrganisme"
            >
              <cleave
                id="numeroOrganisme"
                v-model="ConjointLocal.numeroOrganisme"
                class="form-control"
                :raw="false"
                :options="options.block"
              />
            </b-form-group>
          <b-form-invalid-feedback :state="!hasErrors('conjoint.numeroOrganisme')">
            Veuillez renseigner le(s) champ(s) obligatoire(s
          </b-form-invalid-feedback>
        </b-col>
        </b-row>
      </b-card-text> -->

      <!-- <b-card-text v-if="objets.objet_sante_tns.enfantCharge>0 && renderComponent">
        <h4 class="title-custom-wizard mb-2"><u>ENFANTS</u></h4>
        <b-row v-for="(enfant,index) in enfantsData" :key="index">
          <b-col md="12">
              <h6 class="">Enfant {{index+1}}</h6>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Civilité"
              label-for="civilite"
            >
              <b-form-select
                v-model="enfant.civilite"
                id="civilite"
              >
                <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                <b-form-select-option value="MR">M.</b-form-select-option>
                <b-form-select-option value="MME">Mme</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.civilite')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <b-form-input
                id="nom"
                v-model="enfant.nom"
                trim
                placeholder="Nom"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.nom')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prénom"
              label-for="prenom"
            >
              <b-form-input
                id="prenom"
                v-model="enfant.prenom"
                trim
                placeholder="Prénom"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.prenom')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Date de naissance"
              label-for="date_naissance"
            >
              <flat-pickr
                class="form-control"
                disabled
                v-model="enfant.date_naissance"
                id="date_naissance"
                :config="config"
                placeholder="Date de naissance"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.date_naissance')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="9" class="mb-2">
            <label>L'enfant possède un numéro securité sociale ?</label>
            <b-form-radio-group
              label="Option conjoint"
              v-model="enfant.hasnumsecu"
              :options="BooleanValue"
            ></b-form-radio-group>
          </b-col>
          <b-col md="6" v-if="enfantsData[index].hasnumsecu">
            <b-form-group
              label="Numéro securite sociale"
              label-for="num_secu"
            >
              <b-form-input
                id="num_secu"
                v-model="enfant.num_secu"
                trim
                placeholder="Numéro securite sociale"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.num_secu')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6" v-if="enfantsData[index].hasnumsecu">
            <b-form-group
              label="Code organisme"
              label-for="numeroOrganisme"
            >
              <cleave
                id="numeroOrganisme"
                v-model="enfant.numeroOrganisme"
                class="form-control"
                :raw="false"
                placeholder="Code organisme"
                :options="options.block"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.numeroOrganisme')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
          <b-col md="6" v-if="!enfantsData[index].hasnumsecu">
            <b-form-group
              label="Rattaché a"
              label-for="ayantdroit"
            >
              <b-form-select
                v-model="enfant.ayantdroit"
                id="ayantdroit"
              >
                <b-form-select-option value="sans" disabled>Rattaché a</b-form-select-option>
                <b-form-select-option value="signataire">Signataire</b-form-select-option>
                <b-form-select-option value="conjoint" v-if="objets.objet_sante_tns.conjoint">Conjoint</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-invalid-feedback :state="!hasErrors('enfant.'+index+'.ayantdroit')">
              Veuillez renseigner le(s) champ(s) obligatoire(s
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-card-text> -->
  
      <b-row>
          <b-col lg="12" class="mt-4">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="submit"
            >
              Suivant
            </b-button>
          </b-col>
      </b-row>
      
   </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required,minLength,maxLength,numeric,email} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import moment from 'moment'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Data from "@/shared/constants/risquebesoin";
import DataPROSPECT from "@/shared/constants/prospect";
import {is422} from '@/shared/utils/response'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormSelectOption,
  BAlert

} from "bootstrap-vue";
//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormSelectOption,
    Cleave,
    BAlert
  },
  props:['showLoading','updatedentreprise'],
  computed: {
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Conjoint"]),
    ...mapGetters(["Entreprise"]),
    ...mapGetters(["Enfant"]),
    ...mapGetters(["getItemsRisque"]),
  },
  watch: {
    // Conjoint: {
    //     handler: function (val, oldVal) {
    //       this.ConjointLocal = val;
    //     },
    //     immediate: true,
    //     deep:true
    // },
    Entreprise: {
        handler: function (val, oldVal) {
          this.EntrepriseArray = val;
        },
        immediate: true,
    },
    // Enfant: {
    //     handler: function (val, oldVal) {
    //       this.EnfantsGlobalLocal = val;
    //     },
    //     immediate: true,
    // },
    getItemsRisque: {
        handler: function (val, oldVal) {
          this.rl=val.ItemsRisque[0]
          this.objets=val.ItemsRisque[0].objets
          //this.getEnfantsData()
          this.getEntrepriseData()
          //this.getMandatRia()
        },
        immediate:true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
    choosedEntreprise: function (val, old) {
      let foundEntreprise=_.find(this.EntrepriseGlobalLocal, function(ep) { return ep.id == val; });
      if (foundEntreprise) {
        console.log(this.EntrepriseLocal)
        this.EntrepriseLocal.id=foundEntreprise.id
        this.EntrepriseLocal.denomination_commercial=foundEntreprise.denomination_commercial
        this.EntrepriseLocal.raison_sociale=foundEntreprise.raison_sociale
        this.EntrepriseLocal.siret=foundEntreprise.siret
        this.EntrepriseLocal.code_naf_id=foundEntreprise.code_naf_id
        this.EntrepriseLocal.forme_juridique=foundEntreprise.forme_juridique
        this.EntrepriseLocal.date_cloture_exercice=foundEntreprise.date_cloture_exercice
        this.EntrepriseLocal.moyen_contact.libelle=foundEntreprise.moyen_contact.libelle
        this.EntrepriseLocal.moyen_contact.lieu_dit_ou_bp=foundEntreprise.moyen_contact.lieu_dit_ou_bp
        this.EntrepriseLocal.moyen_contact.complement_adresse=foundEntreprise.moyen_contact.complement_adresse
        this.EntrepriseLocal.moyen_contact.code_postal=foundEntreprise.moyen_contact.code_postal
        this.EntrepriseLocal.moyen_contact.ville_id=foundEntreprise.moyen_contact.ville_id
      }
    },
     'entreprise.date_cloture_exercice': {
      handler(val) {
        if(this.dataSelectedForEdit.date_cloture_exercice != '')
          this.entreprise.date_cloture_exercice=this.dataSelectedForEdit.date_cloture_exercice
        if(this.entreprise.date_cloture_exercice.length==0){
          this.valdiation=null
        }
        else{
          const jour = this.entreprise.date_cloture_exercice.charAt(0)+this.entreprise.date_cloture_exercice.charAt(1)
          const mois = this.entreprise.date_cloture_exercice.charAt(3)+this.entreprise.date_cloture_exercice.charAt(4)
          if(this.entreprise.date_cloture_exercice.length==2){
          this.entreprise.date_cloture_exercice += '/'
        }
          if ( (mois >= 1 && mois <= 12) && (jour >= 1 && jour <= 31) ){
            if(this.entreprise.date_cloture_exercice.length<5){
              this.valdiation='taper DD/MM'
            }
            else{
              this.valdiation=null
            }
          }
          else{
            this.valdiation='taper DD/MM'
          }
        }
      }
    },
  
  },
  data() {
    return {
      disabled_siret: false,
      ConjointLocal:null,
      valdiation:null,
      entreprise:{
        id:null,
        denomination_commercial:null,
        raison_sociale:null,
        siret:null,
        code_naf_id:null,
        forme_juridique:null,
        date_cloture_exercice:null,
        moyen_contact:{
          libelle:null,
          lieu_dit_ou_bp:null,
          complement_adresse:null,
          code_postal:null,
          ville_id:null,
          villesLocal:[]
        }
      },
      EntrepriseGlobalLocal:[],
      EnfantsGlobalLocal:[],
      enfantsData:[],
      codeNafData:[],
      objetTns:null,
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },     
      },
      configDateNaissance: {
          dateFormat: 'd/m/Y',
          disable:[{from:moment(new Date(), "DD/MM/YYYY").subtract(18, 'years')._d,to:'25/02/3000'}],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      FormJuridique : DataPROSPECT["FORMEJURIDIQUE"],
      statutJuridique : Data["STATUTJURIDIQUE"],
      objets:{
        objet_sante_tns:{
          conjoint:null,
          enfantCharge:null,
          statut_juridique:null,
        }
      },
      rl:null,
      errors:[],
      BooleanValue: [
        { value: true, text: "Oui" },
        { value: false, text: "Non" },
      ],
      renderComponent:true,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        block: {
          blocks: [9],
          uppercase: true,
        },
        blockSecu: {
          blocks: [13],
          uppercase: true,
        },
        blockSiret: {
          blocks: [14],
          uppercase: true,
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2,2,2,2,2],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        cp: {
          blocks: [5],
        },
        date_cloture: {
          date: true,
          delimiter: '/',
          datePattern: ['d','m'],

        }
      },
      choosedEntreprise:null,
      EntrepriseArray:[],
    };
  },
  validations: {
    EntrepriseLocal: { 
      siret: { required },
      date_cloture_exercice :{ required }
    },
  },
  created(){
    this.$http.get(`tiers/getCodeNAf`)
      .then((r) => {
        this.codeNafData=r.data
      })
      .catch((e)=>{
        console.log(e);
      })
  },
  methods: {
    recordDuplicateProjet(data) {
      this.$emit('updateLoading', true)
      this.$http.post(`devis/replicateDevis/${data.devisId}`, data).then(response => {
          if (response.data.success) {
            this.messageToast(response.data.message, 'Succès', 'success')
            if (response.data.data.etude_id !== null) {
              this.$router.push({
                name: 'projets-proposition',
                params: {
                  id: response.data.data.etude_id
                }
              })
            }
          }
        })
        .catch(err => {
          this.$emit('updateLoading', false)
          console.log(err)
        })
    },
    checkDepartement(EntrepriseChosen) {
      let response = false
      let codePostalEntreprise = EntrepriseChosen.moyen_contact.code_postal
      if(codePostalEntreprise && codePostalEntreprise.length >= 2 && this.objets.objet_sante_tns.departement != codePostalEntreprise.slice(0, 2)) {
        this.$swal({
          html: 'Le code postal de l’entreprise ne correspond pas au département indiqué sur le devis.<br><br> Si vous souhaitez créer un nouveau Devis reprenant l’ensemble des données, merci de confirmer. Celui-ci sera clôturé sans suite',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        .then(result => {
          let data = {
            devisId: this.rl.devis.id,
            dateEffet: null,
            action: ''
          }
          if(result.value) {
            response = true
            data.action = 'duplicateWithArchive'
            this.recordDuplicateProjet(data)
          } else response = false
        });
      } else response = true
      return response
    },
    GetBySiret(siret, entrepriseSelected) {
      this.disabled_siret = true
      this.$emit('updateLoading',true)
      this.$http.post(`getEnregistrementBySiret`, {
        siret: siret
      }).then(res => {
        if(res.data.data){
          this.EntrepriseArray[entrepriseSelected].moyen_contact.libelle = res.data.data.numVoie+' '+res.data.data.typeVoie+' '+res.data.data.voie
          this.EntrepriseArray[entrepriseSelected].moyen_contact.libelle = this.EntrepriseArray[entrepriseSelected].moyen_contact.libelle.replace(/\s+/g,' ').trim().toLowerCase();
          this.EntrepriseArray[entrepriseSelected].moyen_contact.code_postal = res.data.data.codePostal
          this.EntrepriseArray[entrepriseSelected].denomination_commercial = res.data.data.denomination
          this.EntrepriseArray[entrepriseSelected].forme_juridique = res.data.data.forme_juridique
          this.EntrepriseArray[entrepriseSelected].code_naf_id = res.data.data.code_naf
          this.selectCodeNaf(entrepriseSelected, res.data.data.code_naf)
          this.selectVille(entrepriseSelected, res.data.data.codePostal, res.data.data.ville)
            this.$toast({
            component: ToastificationContent,
            props: {
              title: "Le numéro siret est valide.",
              icon: 'BellIcon',
              variant: 'success'
            }
          })
          this.disabled_siret = false
          this.$emit('updateLoading',false)
        }
      }).catch(err => {
        this.disabled_siret = false
        this.$emit('updateLoading',false)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Le numéro siret est invalide.",
            icon: 'BellIcon',
            variant: 'danger'
          }
        })
      })
    },
    forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
        this.renderComponent = true;
        });
    },
    changeChoosedEntreprise(value){
      this.EntrepriseArray.map((entreprise,key)=>{
        this.EntrepriseArray[key].choosedEntreprise = key == value
      })
      this.$emit('updatedentreprise')
      this.forceRerender()
    },
    addEntreprise(){
      this.EntrepriseArray.push({
        id:null,
        denomination_commercial:null,
        raison_sociale:null,
        siret:null,
        code_naf_id:null,
        forme_juridique:null,
        choosedEntreprise:false,
        date_cloture_exercice:null,
        moyen_contact:{
          libelle:null,
          lieu_dit_ou_bp:null,
          complement_adresse:null,
          code_postal:null,
          ville_id:null,
          villesLocal:[],
        }
      })
      // this.EntrepriseLocal.id=null
      // this.EntrepriseLocal.denomination_commercial=null
      // this.EntrepriseLocal.raison_sociale=null
      // this.EntrepriseLocal.siret=null
      // this.EntrepriseLocal.code_naf_id=null
      // this.EntrepriseLocal.forme_juridique=null
      // this.choosedEntreprise=null
    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:2,
            active:'souscripteur'
          },
      });
    },
    async getEnfantsData(){
      await this.$http
        .get(`devis/getProspectDevisEnfants/${this.rl.devis.id}`)
        .then(r => {
          this.mapEnfantForStore(r.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    async getEntrepriseData(){
      await this.$http
        .get(`devis/getProspectDevisEntreprises/${this.rl.devis.id}`)
        .then(r => {
          this.mapEntreptiseForStore(r.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    mapEnfantForStore(enfants){
      Object.entries(this.EnfantsGlobalLocal.Enfant).forEach(([key, enf]) => {
        let enfant={
          id:enf.lienable.id,
          civilite:enf.lienable.civilite,
          nom:enf.lienable.nom,
          prenom:enf.lienable.prenom,
          date_naissance:enf.lienable.date_naissance,
          num_secu:enf.lienable.num_secu,
          numeroOrganisme:enf.lienable.numeroOrganisme,
          ayantdroit:enf.lienable.ayantdroit,
          hasnumsecu:false,
          statut_gda:enf.lienable.statut_gda
        }

        if(enfant.ayantdroit=='sans'){
          enfant.hasnumsecu=true
        }
        let checkExist=_.find(enfants, function(e) { return e.personne_physique_id == enfant.id; });
        if(checkExist){
          enfant.date_naissance=moment(enfant.date_naissance,'YYYY-MM-DD').format('DD/MM/YYYY'),
          this.enfantsData.push(enfant)
        }
      })
    },
    mapEntreptiseForStore(entreprises){
      this.EntrepriseArray.map((ep,key)=>{
        this.EntrepriseArray[key].choosedEntreprise=false
        this.EntrepriseArray[key].moyen_contact.villesLocal=this.cpKeyupEntreprise(key,ep.moyen_contact.code_postal)

        let foundEntreprise=_.find(entreprises, function(e) { return e.personne_morale_id == ep.id; });
        if (foundEntreprise) {
          this.EntrepriseArray[key].choosedEntreprise=true
          this.EntrepriseArray[key].id    = foundEntreprise.personne_morale_id
          this.EntrepriseArray[key].type  = foundEntreprise.type = 'PERSONNE_MORALE'
        }

        this.$store.commit("setObjetEntreprise", {
          Entreprise: this.EntrepriseArray,
        });

        this.forceRerender()

        // if (foundEntreprise) {
        //   this.EntrepriseLocal.id=ep.id
        //   this.EntrepriseLocal.denomination_commercial=ep.denomination_commercial
        //   this.EntrepriseLocal.raison_sociale=ep.raison_sociale
        //   this.EntrepriseLocal.siret=ep.siret
        //   this.EntrepriseLocal.code_naf_id=ep.code_naf_id
        //   this.EntrepriseLocal.forme_juridique=ep.forme_juridique
        //   this.choosedEntreprise=ep.id
        //   this.$store.commit("setObjetEntreprise", {
        //     Entreprise: this.EntrepriseLocal,
        //   });
        //   return false;
        // }
      })

    },
    async cpKeyupEntreprise(index,cp) {
      if(cp && cp.length==5){
          try {
            this.EntrepriseArray[index].moyen_contact.villesLocal = (await this.$http.get(`helpers/${cp}/villes`)).data;
            this.forceRerender()
          } catch (e) {
            this.EntrepriseArray[index].moyen_contact.villesLocal = []
            this.EntrepriseArray[index].moyen_contact.ville_id = null
            this.entreprise.moyen_contact.ville_id = null
          }
      }else{
            this.EntrepriseArray[index].moyen_contact.villesLocal = []
            this.EntrepriseArray[index].moyen_contact.ville_id = null
            this.entreprise.moyen_contact.ville_id = null

      }
    },
    async selectVille(entrepriseSelected, cp, ville) {
      await this.cpKeyupEntreprise(entrepriseSelected, cp)
      let villeSelected = this.EntrepriseArray[entrepriseSelected].moyen_contact.villesLocal.find(v => v.ville.toLowerCase().includes(ville.toLowerCase()))
      if(villeSelected !== undefined)
        this.EntrepriseArray[entrepriseSelected].moyen_contact.ville_id = villeSelected.id
    },
    selectCodeNaf(entrepriseSelected, value) {
      if(value) {
        let codeSelected = this.codeNafData.find(code => code.code == value.slice(0, 2) + "." + value.slice(2))
        if(codeSelected !== undefined)
          this.EntrepriseArray[entrepriseSelected].code_naf_id = codeSelected
      }
    },
    submit(){
      this.errors=[]
      let data= {
        prospect:this.Tiers,
        // enfant:this.enfantsData,
        // conjoint:this.ConjointLocal,
        entreprise:this.EntrepriseArray,
        objets:this.objets,
        devis:this.rl.devis.id,
        risque:{
          label:this.rl.risque.label
        },
      }
      
      let foundEntreprise=_.find(this.EntrepriseArray, function(e) { return e.choosedEntreprise; });
      if(foundEntreprise){
        // if(!this.checkDepartement(foundEntreprise))
        //   return 0

        this.$emit('updateLoading',true)
        
        this.$http.post(`portefeuilles/updateDataObjets`,data).then((r) => {
          this.updateDevisSouscriptionAvancement()
  
          // this.$store.commit("setObjetEnfant", {
          //   Enfant: this.enfantsData,
          // });
          this.EntrepriseArray=r.data.entreprise
          this.getEntrepriseData()
          // this.$store.commit("setObjetEntreprise", {
          //   Entreprise: this.EntrepriseLocal,
          // });
          this.$store.commit("setStepTarif", {
              step: {
                indexStep:3,
                active:'paiement'
              },
          });
          this.$emit('updateLoading',false)
        }).catch((err)=>{
            if(is422(err))
            {
                this.$emit('updateLoading',false)
                this.errors=err.response.data.errors
            }
        })
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
          title: 'Merci de sélectionner une entreprise',
          icon: 'EditIcon',
          variant: 'danger',
          },
        })
      }

    },
    hasErrors(val){
      if(this.errors){
        const found = Object.entries(this.errors).find(([key, value]) => key === val);
        return found ? found[1][0] : false
      }
    },
    async updateDevisSouscriptionAvancement(){
      let data={
          id:this.rl.devis.id,
          devis_etape_avancement:'paiement'
      }
      await this.$http.post(`devis/updateDevisSouscriptionState`,data).then((r) => {
          return  true
      }).catch((err)=>{
          console.log(err)
      })
    },
    removeEntreprise(index){
      this.EntrepriseArray.splice(index, 1)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style>
.title-custom-wizard{
    color: #4d25bc ;
}
</style>

